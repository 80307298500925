.t-form select {
  display: block;
  margin-top: 5px;
  width: 100%;
}

.ant-pagination {
  padding: 0;
  margin: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  font-size: 1.125em;
}
.ant-select {
  margin-top: 5px;
}

.ant-pagination-item {
  list-style: none;
  padding-right: 4px;

  /* background-color: #1b3e7c;
  color: #fff;
 */

  padding: 4px;
  min-width: 36px;
  min-height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: none;
  border: none;
}

.ant-pagination-item.ant-pagination-item-active {
  background-color: #1b3e7c;
  color: #fff;
  border-radius: 32px;
}
.ant-pagination-item-link {
  background: none !important;
  border: none !important;
}
.ant-pagination-item.ant-pagination-item-active a {
  color: #fff;
}

.ant-form-item-label > label {
  font-size: 16px;
}
.ant-collapse-content-inactive {
  display: none;
}

.u-log {
  padding: 8px 0;
}
.color-green {
  color: #0b9c23;
}
.color-red {
  color: #ff3918;
}



.r-history {
  width: 100%;
  text-align: left;
  font-size: 1.125em;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}
.r-history li {
  padding-bottom: 8px;
  list-style: none;
}
.r-history li:not(:last-child) {
  border-bottom: 1px dashed #ddd;
  margin-bottom: 16px;
}
.r-history li > h4 {
  font-weight: bold;
}
.r-history h4 {
  font-size: 1.25em;
  margin: 0;
}
.r-history time {
  color: rgba(0, 0, 0, 0.4);
  display: block;
  font-size: 0.935em;
}
.r-more > h4 {
  cursor: pointer;
  margin-bottom: 0;
}
.r-more > h4 * {
  pointer-events: none;
}
.r-more .more {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: 0.5s ease;
  padding: 0;
}
.r-more.active > .more {
  padding: 16px 0;
  height: auto;
  opacity: 1;
}
.r-more.active > h4 > .i-more {
  transform: scaleY(-1);
}
.r-more .r-more h4 {
  font-weight: bold;
  font-size: 1em;
}
.r-more .r-more .more {
  padding-bottom: 0;
}

.error {
  color: #ff3918;
}
.select-container {
  margin-top: 5px
}

.round-41 .num{
  background-color: #00709a;
}
.round-42 .num{
  background-color: #00709a;
}

.btn-main.disabled {
  background-color: #e5e5e7;
  background: #e5e5e7;
  color: #191e23;
  cursor: not-allowed;
}